
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "./src/Layout.js";
import sw60 from "./static/strokeweight-060.woff2";
import sw100 from "./static/strokeweight-100.woff2";
import sw140 from "./static/strokeweight-140.woff2";
import { StyleGlobals } from "./src/StyleGlobals";
import CloudContainer from "./src/CloudContainer.js";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Head = makeShortcode("Head");
const Header = makeShortcode("Header");
const Notes = makeShortcode("Notes");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head mdxType="Head">
  <style>
    {`@font-face {
      font-family: strokeweight;
      src: url(${sw60}) format("woff2");
      font-weight: 60;
    }
    @font-face {
      font-family: strokeweight;
      src: url(${sw100}) format("woff2");
      font-weight: 100;
    }
    @font-face {
      font-family: strokeweight;
      src: url(${sw140}) format("woff2");
      font-weight: 140;
    }`}
  </style>
    </Head>
    <Header mdxType="Header">
  <StyleGlobals mdxType="StyleGlobals" />
  <CloudContainer mdxType="CloudContainer" />
    </Header>
    <Layout mdxType="Layout">
      <h1>{`kind cloud`}</h1>
      <p>{`Side project by Shalanah Dawson`}</p>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`Hi, I'm going to be presenting`}</p>
      <p>{`a side project app of mine called`}</p>
      <p>{`kind cloud`}</p>
      <p>{`Before I get started...`}</p>
      <p>{`I'd like to thank JSMN`}</p>
      <p>{`for having me`}</p>
      <p>{`and for providing such a great place`}</p>
      <p>{`to give talks and meet other coders in the Twin Cities.`}</p>
      <p>{`Kudos to the whole board, thank you.`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Hi! 👋`}</h1>
      <h2>{`I'm Shalanah!`}</h2>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`Hi, I'm Shalanah`}</p>
      <p>{`Couple tings about me if we haven't gotten the chance to meet yet...`}</p>
      <p>{`I live in Saint Paul`}</p>
      <p>{`I am a front end dev @whcc in Eagan`}</p>
      <p>{`I play broomball year-round`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`History`}</h1>
      <h1>{`😢`}</h1>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`Many years back`}</p>
      <p>{`I was going through a really bad time at work`}</p>
      <p>{`In particular with a coworker`}</p>
      <p>{`We had gone from a really great mentorship relationship to`}</p>
      <p>{`one where I dreaded going to work`}</p>
      <p>{`After a while I realized it was workplace bullying`}</p>
      <p>{`Which is a really weird thing to realize as an adult`}</p>
      <p>{`Like, how can this be?`}</p>
      <p>{`It dawned on me because I'd try different approaches with him and he'd consitently find new ways to make my experience bad.`}</p>
      <p>{`So I realized it wasn't based on anything I was saying`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Research`}</h1>
      <h1>{`🔎`}</h1>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`So I did what I do with all problems...`}</p>
      <p>{`I obsessively researched bullying`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Self-compassion`}</h2>
      <h1>{`🔑`}</h1>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`Through my research I found a study`}</p>
      <p>{`that found a link between low self-compassion`}</p>
      <p>{`and being a bully or a victim of bullying`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Loving-kindness`}</h2>
      <h1>{`❤️`}</h1>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`Found loving-kindness meditation or metta`}</p>
      <p>{`I'm not an expert on the orgins of loving-kindness`}</p>
      <p>{`but I really loved how it worked and how algorithmic it is`}</p>
      <p>{`So I'd use this meditation and it really did help calm me.`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`How it works`}</h1>
      <h3>{`Say kind things to...`}</h3>
      <p>{`⭐️ Yourself`}</p>
      <p>{`❤️ A loved one`}</p>
      <p>{`⚪️ A neutral party`}</p>
      <p>{`⚡️ Someone you find difficult`}</p>
      <p>{`A larger community`}</p>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`I had a hard time remembering which one I was on`}</p>
      <p>{`so I thought an app would be perfect for it`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>
  <a target={"_black"} href={"https://kindcloud.app"}>
    KindCloud.app
  </a>
      </h2>
    </Layout>
    <Notes mdxType="Notes">
      <p>{`So let's jump into the app`}</p>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Goals`}</h1>
      <p>{`Non-addictive`}</p>
      <p>{`Deliberately slow`}</p>
      <p>{`Peaceful yet welcoming`}</p>
      <p>{`Dark mode`}</p>
      <p>{`Sound-free`}</p>
    </Layout>
    <hr></hr>
    <Layout tech mdxType="Layout">
      <h1>{`Tech`}</h1>
      <p>{`PWA`}</p>
      <p>{`React`}</p>
      <p>{`React`}{` `}{`Router`}</p>
      <p>{`Local`}{` `}{`Storage`}</p>
      <p>{`Styled`}{` `}{`Components`}</p>
      <p>{`SVG`}</p>
      <p>{`Canvas`}</p>
      <h3>{`Animations`}</h3>
      <p>{`GSAP`}</p>
      <p>{`React-Transition-Group`}</p>
      <p>{`RAF`}{` `}{`(Request`}{` `}{`Animation`}{` `}{`Frame)`}</p>
      <p>{`CSS`}{` `}{`transitions`}{` `}{`+`}{` `}{`animations`}</p>
    </Layout>
    <Notes mdxType="Notes">
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Thank You!`}</h1>
      <h3>{`Contact`}</h3>
      <p>{`Github: `}<a parentName="p" {...{
          "href": "http://github.com/shalanah"
        }}>{`@shalanah`}</a></p>
      <p>{`Twitter: `}<a parentName="p" {...{
          "href": "https://mobile.twitter.com/shalanahfaith"
        }}>{`@shalanahfaith`}</a></p>
      <h3>{`Links`}</h3>
      <p><a parentName="p" {...{
          "href": "https://github.com/shalanah/talks-kind-cloud"
        }}>{`Slides (mdx-deck)`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://medicalxpress.com/news/2011-02-personality-traits-bullies-victims.html"
        }}>{`Article: Study Analyzes Personality Traits of Bullies, Victims`}</a></p>
    </Layout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;